import DownloadException from './exception';

const DEFAULT_PARAMS = {
  timeout: 300000,
  headers: [],
  forceDesktopMode: false,
  autoStart: true,
  withCredentials: false,
  method: 'GET',
  nameCallback: (name) => name,
};

class JsFileDownloader {
  /**
   * Create a new JsFileDownloader instance
   * You need to define a {String} "url" params and other
   * @param {Object} customParams
   */
  constructor(customParams = {}) {
    this.params = { ...DEFAULT_PARAMS, ...customParams };
    this.link = this.createLink();
    this.request = null;

    if (this.params.autoStart) {
      return this.downloadFile();
    }

    return this;
  }

  start() {
    return this.downloadFile();
  }

  downloadFile() {
    return new Promise((resolve, reject) => {
      this.initDownload(resolve, reject);
    });
  }

  initDownload(resolve, reject) {
    // fallback for old browsers
    if (!('download' in this.link) || this.isMobile()) {
      this.link.target = '_blank';
      this.link.href = this.params.url;
      this.clickLink();
      return resolve();
    }

    this.request = this.createRequest();

    if (!this.params.url) {
      return reject(new DownloadException('url param not defined!', this.request));
    }

    this.request.onload = () => {
      if (parseInt(this.request.status, 10) !== 200) {
        reject(new DownloadException(`status code [${this.request.status}]`, this.request));
        return;
      }

      const arrayBuffer = this.request.response;

      const blob = new Blob([arrayBuffer]);
      const reader = new FileReader();
      reader.readAsText(blob, 'utf-8');
      reader.onload = () => {
        try {
          const response = JSON.parse(reader.result);
          resolve(response);
        } catch (e) {
          this.startDownload();
          resolve(this.request.response);
        }
      };
    };

    this.request.ontimeout = () => {
      reject(new DownloadException('request timeout', this.request));
    };

    this.request.onerror = () => {
      reject(new DownloadException('network error', this.request));
    };

    this.request.send();

    return this;
  }

  isMobile() {
    return !this.params.forceDesktopMode && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  }

  createRequest() {
    const request = new XMLHttpRequest();

    request.open(this.params.method, this.params.url, true);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    this.params.headers.forEach((header) => {
      request.setRequestHeader(header.name, header.value);
    });
    request.responseType = 'arraybuffer';
    if (this.params.process && typeof this.params.process === 'function') {
      request.addEventListener('progress', this.params.process);
    }
    request.timeout = this.params.timeout;
    request.withCredentials = !!this.params.withCredentials || !!this.params.includeCredentials;
    return request;
  }

  getFileName() {
    // Forcing file name
    if (typeof this.params.filename === 'string') {
      return this.params.filename;
    }
    // Trying to get file name from response header
    const content = this.request.getResponseHeader('Content-Disposition');
    let contentParts = [];

    if (content) {
      contentParts = content.replace(/["']/g, '').match(/filename\*?=([^;]+)/);
    }

    const extractedName = contentParts && contentParts.length >= 1
      ? contentParts[1]
      : this.params.url.split('/').pop().split('?')[0];

    return decodeURIComponent(this.params.nameCallback(extractedName));
  }

  // eslint-disable-next-line class-methods-use-this
  createLink() {
    const link = document.createElement('a');

    link.style.display = 'none';
    return link;
  }

  clickLink() {
    let event;

    try {
      event = new MouseEvent('click');
    } catch (e) {
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }

    this.link.dispatchEvent(event);
  }

  // eslint-disable-next-line class-methods-use-this
  getFile(response, fileName) {
    let file;
    const options = { type: 'application/octet-stream' };

    try {
      file = new File([response], fileName, options);
    } catch (e) {
      file = new Blob([response], options);
      file.name = fileName;
      file.lastModifiedDate = new Date();
    }
    return file;
  }

  startDownload() {
    const fileName = this.getFileName();
    const file = this.getFile(this.request.response, fileName);

    // native IE
    if ('msSaveOrOpenBlob' in window.navigator) {
      return window.navigator.msSaveOrOpenBlob(file, fileName);
    }

    const objectUrl = window.URL.createObjectURL(file);

    this.link.href = objectUrl;
    this.link.download = fileName;
    this.clickLink();

    setTimeout(() => {
      (window.URL || window.webkitURL || window).revokeObjectURL(objectUrl);
    }, 1000 * 40);

    return this;
  }
}

function downloadFileExtra({
  url, fileName, method, token,
}) {
  const params = { url };
  if (fileName) {
    params.filename = fileName;
  }
  if (method) {
    params.method = method;
  }
  if (token) {
    params.headers = [{
      name: 'X-Auth-Token',
      value: token,
    }];
  }

  return new JsFileDownloader(params);
}

function downloadFile(url, fileName) {
  return downloadFileExtra({ url, fileName });
}

export {
  downloadFileExtra,
};

export default downloadFile;
