<template>
  <div class="ph-20">
    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="fetching"
             bordered
             row-key="id"
             class="table small-cell-table">
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="onEdit(record)">编辑</a-button>
      </template>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>

    <EditPostTask ref="editPostTaskRef"
                  @success="updateRecord"/>
  </div>
</template>

<script>
import table from '@/mixins/table';

import {
  getAcceptanceSettingList,
} from './api';

import EditPostTask from './components/EditPostTask.vue';

const columns = [
  {
    width: '180px',
    align: 'center',
    dataIndex: 'name',
    key: 'name',
    title: '岗位',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'user_num',
    key: 'user_num',
    title: '要求人数',
  },
  {
    width: '280px',
    align: 'center',
    dataIndex: 'learnplan_name',
    key: 'learnplan_name',
    title: '任务（链）名称',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'Setting',
  mixins: [table],
  components: {
    EditPostTask,
  },
  data() {
    return {
      originColumns: columns,
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    getData() {
      this.executeGetData(async () => {
        const data = await getAcceptanceSettingList();
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取数据失败');
          return Promise.reject(data);
        }
        this.data = data.data || [];
        return Promise.resolve(data);
      });
    },
    onEdit(record) {
      if (!record?.id) {
        this.$message.info('没有岗位 id');
        return;
      }
      if (this.$refs.editPostTaskRef?.show) {
        this.$refs.editPostTaskRef.show({
          postStr: record.name || '',
          postId: record.id,
          taskIds: record.chain_id || '',
          userNum: record.user_num || '',
        });
      }
    },

    updateRecord(evt) {
      this.data = this.data.map((item) => {
        const i = { ...item };
        if (i.id === evt.post_id) {
          i.user_num = evt.user_num;
          i.chain_id = evt.task_id;
          i.learnplan_name = evt.task_name;
        }

        return i;
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
