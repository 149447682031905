<template>
  <a-modal :title="title"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="true"
           :width="600"
           :centered="true"
           :destroy-on-close="true"
           :after-close="reset"
           :closable="true"
           @cancel="close()">
    <a-table ref="tableRef"
             :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             :scroll="{y: '60vh'}"
             bordered
             row-key="rowId"
             class="table small-cell-table">
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="preview(record)">预览</a-button>
        <a-button type="link" @click="download(record)">下载</a-button>
      </template>
    </a-table>

    <template slot="footer">
      <a-button type="primary" @click="close()">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import downloadFile from '@/utils/download';
import {
  getAcceptanceCertificateList,
} from '../api';

export default {
  name: 'StudentCertificateList',
  data() {
    return {
      loading: false,
      shown: false,

      userId: '',
      title: '',

      data: [],
      columns: [
        {
          title: '证书名',
          dataIndex: 'certificate_title',
          width: '140px',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '120px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  methods: {
    show({ userId, userName = '' }) {
      this.title = userName + '的证书列表';
      this.userId = userId;

      this.getData();

      this.shown = true;
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.title = '证书列表';
      this.userId = '';
      this.data = [];
    },

    async getData(page = 1, perPage = 10) {
      this.loading = true;
      const data = await getAcceptanceCertificateList({
        user_id: this.userId,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取证书列表失败');
        return;
      }
      this.data = (data?.data || []).map((i, index) => ({
        ...i,
        rowId: (page - 1) * perPage + index,
      }));
    },

    preview(record) {
      window.open(record.url, '_blank');
    },
    download(record) {
      downloadFile(record.download_url);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
