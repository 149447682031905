<template>
  <div>
    <a-form :form="form" layout="inline" class="ph-20 mb-20">
      <a-form-item label="* 体验中心全称">
        <app-select-org v-model="form.org_id"
                        :enabled-depth="[4]"
                        :max="1"/>
      </a-form-item>

      <a-form-item label="岗位">
        <app-select-job v-model="form.post_id"
                        :list="postList"/>
      </a-form-item>

      <a-form-item label="姓名">
        <a-input v-model="form.user_name"
                 :allow-clear="true"
                 placeholder="请输入姓名"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="onSearch">查询</a-button>
        <a-button :disabled="downloading" type="primary" @click="exportExcel">
          导出单店结果
        </a-button>
      </a-form-item>
    </a-form>

    <div class="ph-20 full-width mb-5">
      <a-descriptions :title="status"
                      layout="vertical"
                      bordered
                      :column="{ xxl: 10, xl: 8, lg: 6, md: 4, sm: 2, xs: 1 }">
        <a-descriptions-item v-for="(item) in postFinishStatusList"
                             :key="item.post_id"
                             :label="item.label">
          {{ item.content }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="ph-20 full-width mb-20">已完成人数/未完成人数</div>

    <div class="ph-20 full-width">
      <a-table ref="tableRef"
               :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="fetching"
               :scroll="{x: '100%'}"
               bordered
               row-key="rowId"
               class="table small-cell-table">
        <template slot="certificate_status" slot-scope="text, record">
          <span v-if="!text">无</span>
          <a-button v-else type="link" @click="viewCertificates(record)">查看证书</a-button>
        </template>
      </a-table>
    </div>

    <StudentCertificateList ref="studentCertificateListRef"/>
  </div>
</template>

<script>
import table from '@/mixins/table';

import {
  getAcceptancePostList,
  getAcceptanceQueryList,
  getAcceptanceQueryListDownload,
} from './api';

import StudentCertificateList from './components/StudentCertificateList.vue';

const defaultForm = {
  org_id: '',
  post_id: '',
  user_name: '',
};

const columns = [
  {
    title: '姓名',
    dataIndex: 'truename',
    width: '100px',
    align: 'center',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    width: '120px',
    align: 'center',
  },
  {
    title: '岗位',
    dataIndex: 'post_name',
    width: '100px',
    align: 'center',
    autoExpand: true,
  },
  {
    title: '身份证号',
    dataIndex: 'idcard',
    width: '200px',
    align: 'center',
  },
  {
    title: '账号状态',
    dataIndex: 'status_name',
    width: '100px',
    align: 'center',
  },
  {
    title: '任务（链）状态',
    dataIndex: 'task_status_name',
    width: '140px',
    align: 'center',
  },
  {
    title: '证书',
    dataIndex: 'certificate_status',
    width: '140px',
    align: 'center',
    fixed: 'right',
    scopedSlots: { customRender: 'certificate_status' },
  },
];

export default {
  name: 'Query',
  mixins: [table],
  components: {
    StudentCertificateList,
  },
  data() {
    return {
      defaultForm,
      form: { ...defaultForm },

      originColumns: columns,

      postList: [],
      postFinishStatusList: [],

      status: '',
    };
  },
  created() {
    this.getPostList();
  },
  methods: {
    async getPostList() {
      const data = await getAcceptancePostList();
      this.postList = (data?.data || []).map(item => ({
        name: item.name,
        id: item.post_id,
      }));
    },

    async getData(page = 1, perPage = 10) {
      this.executeGetData(async () => {
        if (!this.form.org_id) {
          this.$message.info('请选择体验中心');
          return Promise.reject(new Error('请选择体验中心'));
        }
        const data = await getAcceptanceQueryList(this.form);
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取数据失败');
          return Promise.reject(data);
        }
        this.status = `当前状态：${data?.meta?.status || ''}`;
        this.postFinishStatusList = (data?.meta?.post_list || []).map((i) => ({
          ...i,
          label: i.post_name,
          content: `${i.num}/${i.user_num}`,
        }));
        this.data = (data.data || []).map((i, index) => ({
          ...i,
          rowId: (page - 1) * perPage + index,
        }));
        return Promise.resolve(data);
      });
    },

    exportExcel() {
      this.executeExportExcel(async () => {
        const data = await getAcceptanceQueryListDownload({
          ...this.form,
          is_download: 1,
        });
        if (!data || data.error_code) {
          return Promise.reject(data);
        }
        return Promise.resolve(data);
      });
    },

    viewCertificates(record) {
      if (this.$refs.studentCertificateListRef?.show) {
        this.$refs.studentCertificateListRef.show({
          userId: record.user_id,
          userName: record.truename,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ant-descriptions-title {
    margin-bottom: 5px;
  }

  .ant-descriptions-item-label, .ant-descriptions-item-content {
    padding: 6px 10px;
  }
}
</style>
