import Pagination, {
  getPagination,
  updatePagination,
} from '@/components/table/Pagination.vue';

const table = {
  components: {
    Pagination,
  },
  computed: {
    columns() {
      return [...this.originColumns];
    },
  },
  watch: {
    columns: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.fixTableBlankColumn());
      },
    },
  },
  data() {
    return {
      fetching: false,
      downloading: false,
      saving: false,
      defaultForm: {},

      form: {},
      originColumns: [],
      data: [],
      pagination: getPagination(),
    };
  },
  methods: {
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onReset(fetch = true) {
      if (fetch) {
        this.onSearch();
      } else {
        this.form = { ...this.defaultForm };
        this.data = [];
        this.pagination = getPagination();
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    onRefreshPage() {
      this.onSearch(
        this.pagination.current,
        this.pagination.pageSize,
      );
    },
    updatePagination(pagination) {
      this.pagination = updatePagination(this.pagination, pagination);
    },

    async getData() {
    },
    async executeGetData(getData) {
      if (this.fetching) {
        return Promise.reject(new Error('Loading'));
      }
      this.fetching = true;

      try {
        return getData().then((data) => {
          this.updatePagination(data?.meta?.pagination || {});
        }).catch(() => {
          this.fetching = false;
        }).finally(() => {
          this.fetching = false;
        });
      } catch (e) {
        this.fetching = false;
        return Promise.reject(e);
      }
    },

    exportExcel() {
    },
    executeExportExcel(exportExcel) {
      if (this.downloading) {
        return Promise.reject(new Error('Loading'));
      }
      this.downloading = true;

      try {
        return exportExcel().then(() => {
          this.$message.success('导出成功');
        }).catch((data) => {
          this.$message.error(data?.message || '导出失败');
          this.downloading = false;
        }).finally(() => {
          this.downloading = false;
        });
      } catch (e) {
        this.$message.error('导出失败');
        this.downloading = false;
        return Promise.reject(e);
      }
    },

    fixTableBlankColumn() {
      if (!this.$refs?.tableRef) {
        return;
      }
      const table = this.$refs.tableRef.$el;
      const colgroup = table.querySelector('colgroup');
      const cols = colgroup?.querySelectorAll('col') || [];

      const fixedColIndex = this.columns.findIndex((item) => item.autoExpand);
      if (fixedColIndex === -1) {
        return;
      }
      [].forEach.call(cols, (col, index) => {
        if (fixedColIndex === index) {
          if (!col.style) {
            col.style = {};
          }
          col.style.width = 'auto';
        } else {
          col.style.width = this.columns[index].width || col.style.minWidth || 'auto';
        }
      });
    },
  },
};

export default table;
