<template>
  <a-modal :title="title"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="true"
           :width="600"
           :centered="true"
           :destroy-on-close="true"
           :after-close="reset"
           @ok="confirm"
           @cancel="close">
    <a-form :form="form"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 16 }">
      <a-form-item label="应完成人数">
        <a-input-number v-decorator="['user_num', decorator.user_num]"
                        :min="0"
                        placeholder="请输入应完成人数"
                        style="min-width: 180px;"/>
      </a-form-item>

      <a-form-item label="应完成任务（链）">
        <app-select-base v-decorator="['task_id', decorator.task_id]"
                         :options="{id: 'chain_id', name: 'title'}"
                         :list="taskList"
                         mode="default"
                         placeholder="请选择应完成任务（链）"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  getAcceptanceTaskList,
  saveAcceptanceTaskList,
} from '../api';

export default {
  name: 'EditPostTask',
  data() {
    return {
      loading: false,
      shown: false,

      title: '',
      postId: '',

      taskList: [],

      decorator: {
        task_id: {
          rules: [{ required: true, message: '请选择应完成任务（链）' }],
        },
        user_num: {
          rules: [{ required: true, message: '请输入应完成人数' }],
        },
      },
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    show({ postStr, postId, taskIds, userNum }) {
      this.title = postStr + '岗位';
      this.postId = postId;

      this.getTaskList();

      this.shown = true;

      this.$nextTick(() => this.setDecorator({
        task_id: `${taskIds}` || undefined,
        user_num: userNum || undefined,
      }));
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.title = '岗位';
      this.postId = '';
      this.taskList = [];
    },
    confirm() {
      this.form.validateFields((errors, values) => {
        if (errors) {
          return;
        }

        this.saveAcceptanceTaskList({
          ...values,
          task_id: +values.task_id,
          post_id: this.postId,
        });
      });
    },

    setDecorator(data = {}) {
      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        values[key] = data[key] ?? undefined;
      });

      this.form.setFieldsValue(values);
    },

    async getTaskList() {
      this.loading = true;
      const data = await getAcceptanceTaskList({
        post_id: this.postId,
        post_name: '',
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取任务链失败');
        return;
      }
      this.taskList = data?.data || [];
    },

    async saveAcceptanceTaskList(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await saveAcceptanceTaskList(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');
      this.$emit('success', {
        ...params,
        task_name: this.taskList.filter(
          (i) => i.chain_id === params.task_id,
        )?.[0]?.title || '',
      });
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">

</style>
