import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/JsDownload';

// 筹建店验收配置列表
export function getAcceptanceSettingList(params) {
  return request({
    url: '/post/admin/acceptanceList',
    method: 'get',
    params,
  });
}

// 可选择的任务列表
export function getAcceptanceTaskList(params) {
  return request({
    url: '/post/admin/chooseLearnplanList',
    method: 'get',
    params,
  });
}

// 岗位配置任务
export function saveAcceptanceTaskList(data) {
  return request({
    url: '/post/admin/postConfigurationTask',
    method: 'post',
    data,
  });
}

// 可选择的岗位列表
export function getAcceptancePostList() {
  return request({
    url: '/post/admin/canQueryPostList',
    method: 'get',
  });
}

// 用户证书列表
export function getAcceptanceCertificateList(params) {
  return request({
    url: '/post/admin/userCertificateList',
    method: 'get',
    params,
  });
}

// 筹建店验收查询列表
export function getAcceptanceQueryList(params) {
  return request({
    url: '/post/admin/acceptanceQueryList',
    method: 'get',
    params,
  });
}


// 筹建店验收查询列表 - 导出 excel
export function getAcceptanceQueryListDownload(params) {
  let url = `/post/admin/acceptanceQueryList`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
